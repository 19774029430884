@import 'assets/less/variables';

.link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 940px;
  height: 72px;
  padding: 0 24px;
  background: @background-1;

  &.ant-typography {
    margin-bottom: 0;
  }

  &__code {
    overflow: hidden;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: @gray-1;
  }

  &__copy {
    &--copied path {
      fill: @primary;
    }
  }

  .screen-sm({
    height: 48px;
    padding: 0 16px;
    font-size: 14px;
  });
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';