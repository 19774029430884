@import 'assets/less/variables';

.action-header-wrapper {
  padding: 52px 48px 44px 40px;
  border-bottom: 1px solid @gray-3;

  &__menu {
    margin-bottom: -44px;

    .ant-menu-horizontal {
      border-bottom: 0;

      li.ant-menu-item {
        margin: 0 40px 0 0 !important;
      }

      .screen-lg({
        border-bottom: 1px solid @gray-3;
      });
    }
  }

  .action-header {
    display: flex;

    &__actions {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;

      .screen-lg({
        margin-top: 20px;
      });

      .screen-sm({
        flex-direction: column;
      });
    }

    &__left {
      margin-right: 5px;

      .screen-sm({
        margin-bottom: 20px;

        & div {
          width: 100%;
        }
      });
    }

    &__title {
      margin-right: 32px;
      font-size: 32px;

      .screen-lg({
        margin: 0;
      });
    }

    .screen-lg({
      flex-direction: column;
    });
  }

  &:not(.action-header-wrapper-with-menu) {
    .action-header {
      &__title {
        .screen-lg({
          border-bottom: 1px solid @gray-3;
        });
      }
    }
  }

  .screen-lg({
    padding: 20px 0 0 0;
    border-bottom: none;

    &__menu {
      margin-bottom: 0;
    }
  });
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';