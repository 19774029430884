@import 'assets/less/variables';

.table {
  &__page {
    margin-bottom: 24px;
    border-bottom: 1px solid @gray-3;

    table tr > th {
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      background: transparent;
    }

    table tr > td {
      font-size: 14px;
      color: @gray-1;
      border-bottom: none;
    }

    table tr > th.ant-table-column-has-sorters,
    table tr > th.ant-table-column-has-sorters:hover,
    table tr > td.ant-table-column-sort {
      background: inherit;
    }

    // Change empty table placeholder width which caused bug on resize
    .ant-table-placeholder .ant-table-expanded-row-fixed {
      width: auto !important;
    }
  }
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';