@import 'assets/less/variables';

.gift-item {
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px;
    background: rgba(@gray-3, 0.9);
    opacity: 0;
    transition: opacity 0.3s;
  }

  &__text {
    max-width: 180px;
    font-weight: 700;
    text-align: center;
    color: @gray-1;

    .screen-sm({
      font-size: 12px;
    });
  }

  &__card {
    position: relative;
    z-index: 0;
    width: 100%;
    padding-top: 62.5%;
    overflow: hidden;
    border-radius: @border-radius;
    background: rgba(@gray-3, 0.5);

    &--hover:hover {
      cursor: pointer;
    }

    &:hover .gift-item__overlay {
      opacity: 1;
    }
  }

  &__name {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 700;

    .screen-sm({
      margin-top: 5px;
    });
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateZ(0);
  }
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';