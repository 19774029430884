// General colors
@light: #ffffff;
@dark: #1d2c4c;

@success: #04aa77;
@success-light: #e1ffe2;
@success-secondary: #34c759;
@warning: #ffa800;
@warning-light: #fff2d8;
@error: #ff003d;
@error-light: #ffe1e1;

@background-1: #f2f7fa;

@google: #de4837;

// Shades of
@gray-1: #525975;
@gray-2: #bbbbbb;
@gray-3: #f4f4f6;
@gray-4: #dddddd;

// Main colors
@primary: #00a79d;
@primary-light: rgba(0, 167, 157, 0.05);

@color-1: #005d9c;

// General
@border-radius: 5px;

// Media Queries
@xxl: 1599px;
@xl: 1199px;
@lg: 991px;
@md: 767px;
@sm: 575px;

// Media Queries mixin
.screen-xxl(@rules) {
  @media (max-width: @xxl) {
    @rules();
  }
}
.screen-xl(@rules) {
  @media (max-width: @xl) {
    @rules();
  }
}
.screen-lg(@rules) {
  @media (max-width: @lg) {
    @rules();
  }
}
.screen-md(@rules) {
  @media (max-width: @md) {
    @rules();
  }
}
.screen-sm(@rules) {
  @media (max-width: @sm) {
    @rules();
  }
}

.screen-min-xxl(@rules) {
  @media (min-width: @xxl) {
    @rules();
  }
}
.screen-min-xl(@rules) {
  @media (min-width: @xl) {
    @rules();
  }
}
.screen-min-lg(@rules) {
  @media (min-width: @lg) {
    @rules();
  }
}
.screen-min-md(@rules) {
  @media (min-width: @md) {
    @rules();
  }
}
.screen-min-sm(@rules) {
  @media (min-width: @sm) {
    @rules();
  }
}
