@import 'assets/less/variables';

.company-nav {
  .ant-menu-item-group-title {
    padding-top: 0;
  }

  &__group-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    padding: 0 !important;

    .ant-menu-item-group-title {
      margin-bottom: 8px;
      padding: 0;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      color: @gray-2;
    }

    .screen-sm({
      margin-bottom: 20px;
    });
  }

  &__group-title {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: @gray-2;
  }

  &__icon {
    margin-right: 16px !important;
  }

  &__item,
  &__sub-item {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 !important;
    padding: 8px 0 !important;
    font-weight: 600;
    line-height: 24px;
    color: @gray-1;
    background-color: transparent !important;
    transition: color 0.3s;
  }

  &__item {
    svg rect,
    svg path {
      transition: fill 0.3s;
    }

    &.ant-menu-item-selected {
      font-weight: 700;
      color: @primary;
      background-color: transparent !important;

      &::after {
        display: none !important;
      }

      &::before {
        content: '';
        position: absolute;
        top: unset;
        left: 2px;
        right: unset;
        bottom: 2px;
        width: 20px;
        height: 2px;
        background: @primary;
        transition: none;
      }

      svg rect,
      svg path {
        fill: @primary;
      }
    }

    &.ant-menu-submenu {
      flex-direction: column;

      .ant-menu-sub.ant-menu-inline {
        background-color: transparent;
      }
    }

    .ant-menu-submenu-title {
      display: flex;
      align-items: center;
      height: 24px;
      margin: 0;
      padding-left: 0 !important;
      line-height: 24px;
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
      background: transparent;
    }

    .ant-menu-submenu-arrow {
      position: static !important;
      margin-left: 8px;
    }

    &:hover {
      svg path {
        fill: @primary;
      }
    }
  }

  &__sub-item {
    padding-left: 40px !important;

    &.ant-menu-item-selected {
      &::before,
      &::after {
        display: none;
      }
    }
  }
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';