@import 'assets/less/variables';

.ant-table-filter-column {
  .ant-table-column-sorters {
    width: 100%;
    padding-right: 5px;

    .ant-table-column-sorter {
      margin-left: auto;
    }
  }
}

.table-filter-dropdown {
  padding: 20px 20px 10px;

  input.ant-input {
    padding: 0;
  }

  &__footer {
    margin-top: 16px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';