@import 'assets/less/variables';

.modal-actions {
  display: flex;
  justify-content: space-between;

  &__btn {
    width: 100%;
    max-width: 240px;
    height: 54px;
    font-size: 20px;

    &:first-child {
      margin-right: 10px;
    }

    .screen-sm({
      height: 40px;
      font-size: 14px;
      max-width: none;

      &:first-child {
        margin: 0 0 10px 0;
      }
    });
  }

  .screen-sm({
    flex-direction: column;
  });
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';