@import 'assets/less/variables';

.company-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid @gray-3;

  &__logo {
    display: flex;
    flex-shrink: 0;
    width: 270px;
    margin-left: 32px;

    .screen-lg({
      margin: 0 auto 0 0;
      padding: 20px 0 15px 0;
      width: auto;
    });
  }

  &__logo-link {
    display: inline-flex;

    svg {
      width: 140px;
      height: 40px;
    }

    .screen-lg({
      svg {
        height: 30px;
        width: 107px;
      }
    });
  }

  &__wrap {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    min-height: 72px;
  }

  &__points {
    align-self: stretch;
    width: 100%;
    min-width: auto;
    max-width: 220px;
    margin: 0 20px 0 10px;
    padding: 0 10px;
  }

  &__hamburger {
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
  }

  .screen-lg({
    height: auto;
    margin: 0 20px;
  });
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';