@import 'assets/less/variables';

.refer-candidate {
  padding: 50px 0;

  &__row {
    display: flex;
    margin-left: -40px;

    .screen-sm({
      flex-direction: column;
      margin-left: 0
    });
  }

  &__col {
    width: 100%;
    margin-left: 40px;

    .screen-sm({
      margin-left: 0
    });
  }

  &__reason-row {
    margin-bottom: 40px;
  }

  &__upload {
    margin: 20px 0 4px 0;

    &.ant-upload.ant-upload-drag {
      cursor: auto;
    }

    &.ant-upload.ant-upload-drag .ant-upload {
      height: 60px;
      padding: 0;
    }
  }

  .upload-error {
    .refer-candidate__upload {
      border-color: @error;
    }
  }

  &__upload-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: @gray-1;
    cursor: pointer;

    .screen-sm({
      font-size: 14px
    });
  }

  &__file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    cursor: auto;

    .screen-sm({
      font-size: 14px
    });
  }

  &__file-text {
    margin: 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__file-delete {
    width: 40px;
    height: 40px;
    color: @gray-2;
    border: none;
    outline: none;
    background: transparent;
    transition: color 0.3s;
    cursor: pointer;

    &:hover {
      color: @error;
    }
  }

  &__email {
    margin-bottom: 36px;
    border-bottom: 1px solid @gray-2;

    .screen-sm({
      font-size: 14px
    });
  }

  &__email-title {
    font-weight: 700;
  }

  .screen-sm({
      padding: 24px 0;
    });
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';