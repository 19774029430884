@import 'assets/less/variables';

.departments-form {
  padding: 25px 0 50px 0;

  &__status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 28px;
    font-size: 20px;
    border-bottom: 1px solid @gray-3;
  }

  &__status-switch {
    margin-bottom: 0;
  }
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';