@import 'assets/less/variables';

.page-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.page-card {
  max-width: 600px;
  margin: 160px 20px 60px 20px;

  &__header {
    margin: 40px 0;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
  }

  &__text {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
  }

  .app-logo {
    &__lg {
      width: 100%;
      max-width: 322px;

      svg,
      img {
        width: 100%;
      }
    }
  }

  .screen-sm({
    &__header {
      margin: 32px 0;
      font-size: 32px;
      line-height: 40px;
    }

    &__text {
      font-size: 20px;
      line-height: 24px;
    }

    .app-logo {
      &__lg {
        max-width: 220px;
      }
    }
  });
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';