@import 'assets/less/variables';

.user-info-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 20px 16px 20px;
  padding-right: 44px;
  cursor: pointer;

  &__text {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    overflow: hidden;
    line-height: 1.2;
    text-align: left;
  }

  &__name {
    width: 100%;
    font-weight: 700;
    word-break: break-word;
  }

  &__role {
    font-size: 12px;
    color: @gray-1;
  }

  &__avatar {
    flex-shrink: 0;
    margin-right: 16px;
    background: @color-1;
  }

  &__down {
    flex-shrink: 0;
  }
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';