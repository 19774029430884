@import 'assets/less/variables';

.company-settings-form {
  &__wrapper {
    display: flex;

    .screen-lg({
      flex-direction: column;
    });
  }

  &__form-items {
    width: 100%;
    max-width: 555px;
    margin-right: 20px;
  }

  &__form-item {
    padding-bottom: 20px;
  }

  &__label,
  &__text {
    color: @gray-1;

    .screen-sm({
      font-size: 14px;
    });
  }

  &__text {
    margin-bottom: 20px;
  }

  &__input {
    width: 172px;

    .screen-sm({
      width: 100%;
    });
  }

  &__hint {
    color: @gray-2;

    .screen-sm({
      font-size: 14px;
    });
  }

  &__info {
    max-width: 450px;
    margin-top: 120px;

    .screen-lg({
      max-width: 100%;
      margin: 0 0 20px 0;
    });
  }

  &__percentage {
    display: inline-block;
    margin-bottom: 0;

    .ant-form-item-control-input {
      min-height: auto;
    }

    .ant-input-number-input {
      font-weight: 700;
      text-align: center;
      color: @gray-1;
    }

    &.ant-form-item-has-error .ant-input-number-input {
      color: @error;
    }
  }

  &__percentage-input {
    width: 30px;

    &.ant-input-number.ant-input-number-sm {
      padding: 0;
    }
  }
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';