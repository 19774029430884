body {
  scroll-behavior: smooth;
}
#root,
.App {
  height: 100%;
}
.no-wrap {
  white-space: nowrap;
}
.ant-btn-background-ghost.ant-btn-primary:not(.ant-btn-dangerous) {
  background: rgba(0, 167, 157, 0.05) !important;
}
.ant-input,
.ant-input-affix-wrapper,
.ant-form-item .ant-input-number {
  padding: 16px 0;
  border: none;
  border-bottom: 1px solid #bbbbbb;
  border-radius: 0;
}
.ant-input:focus,
.ant-input-affix-wrapper:focus,
.ant-form-item .ant-input-number:focus,
.ant-input.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
.ant-form-item .ant-input-number.ant-input-affix-wrapper-focused,
.ant-input.ant-input-number-focused,
.ant-input-affix-wrapper.ant-input-number-focused,
.ant-form-item .ant-input-number.ant-input-number-focused {
  box-shadow: none;
}
.ant-input .ant-input-number-handler-wrap,
.ant-input-affix-wrapper .ant-input-number-handler-wrap,
.ant-form-item .ant-input-number .ant-input-number-handler-wrap {
  display: none;
}
.ant-input .ant-input-number-input,
.ant-input-affix-wrapper .ant-input-number-input,
.ant-form-item .ant-input-number .ant-input-number-input {
  padding: 0;
}
@media (max-width: 575px) {
  .ant-input,
  .ant-input-affix-wrapper,
  .ant-form-item .ant-input-number {
    font-size: 14px;
    padding: 14px 0;
  }
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  border-bottom: 1px solid #bbbbbb;
  border-radius: 0;
}
.ant-select.ant-select-single .ant-select-selector {
  border: none;
  border-bottom: 1px solid #bbbbbb;
  border-radius: 0;
}
.ant-select.ant-select-single .ant-select-selector:focus {
  box-shadow: none;
}
@media (max-width: 575px) {
  .ant-select.ant-select-single .ant-select-selector {
    font-size: 14px;
  }
}
.ant-select.ant-select-single .ant-select-selection-item {
  color: #525975;
}
.ant-select.ant-select-single .ant-select-selection-search {
  left: 0;
}
.ant-select.ant-select-single.ant-select-lg .ant-select-selector {
  padding-left: 0;
}
.ant-select.ant-select-single.ant-select-focused .ant-select-selector {
  box-shadow: none !important;
}
.ant-select.ant-select.ant-select-single.ant-select-lg {
  font-size: 16px;
}
.ant-picker {
  border: none;
  border-bottom: 1px solid #bbbbbb;
  border-radius: 0;
}
.ant-picker .ant-picker-input > input {
  color: #525975;
}
@media (max-width: 575px) {
  .ant-picker .ant-picker-input > input {
    font-size: 14px;
  }
}
.ant-picker.ant-picker-focused,
.ant-picker:focus {
  box-shadow: none;
}
@media (max-width: 575px) {
  .ant-picker-panels {
    flex-direction: column;
  }
}
.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus {
  box-shadow: none;
}
.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 14px;
}
.ant-typography-copy {
  width: 24px;
  height: 24px;
}
.text__gray-2 {
  color: #bbbbbb;
}
.text__primary {
  color: #005d9c;
}
.text__error {
  color: #ff003d;
}
.text__bold {
  font-weight: bold;
}
.text__12 {
  font-size: 12px;
}
.text__14 {
  font-size: 14px;
}
.switch-form-item {
  display: flex;
  justify-content: space-between;
}
.switch-form-item__label {
  margin-bottom: 10px;
  padding-bottom: 28px;
  font-size: 20px;
}
