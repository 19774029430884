@import 'assets/less/variables';

@city-field-width: 212px;

.billing-address {
  &__country-row {
    max-width: @city-field-width;
  }

  &__double-row {
    display: flex;
    justify-content: space-between;

    > div {
      width: calc(100% / 2 - 8px);
    }

    .screen-md({
      flex-direction: column;
    });
  }

  &__triple-row {
    display: flex;
    justify-content: space-between;

    .city-field {
      width: 100%;
      max-width: @city-field-width;
    }

    > div:not(.city-field) {
      width: calc((100% - @city-field-width) / 2 - 16px);
    }

    .screen-md({
      flex-direction: column;

      > div:not(.city-field) {
        width: 100%;
      }

      .city-field {
        max-width: 100%;
      }
    });
  }
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';