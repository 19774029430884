@import 'assets/less/variables';

@item-side: 220px;
@item-margin: 32px;

.integration-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: @item-side;
  height: @item-side;
  padding: 24px 12px 16px;
  border: 1px solid @gray-3;
  border-radius: 4px;

  &__logo {
    max-width: 54px;
    border-radius: 4px;
  }

  &__title {
    font-weight: 600;
    line-height: 40px;
  }

  &__description {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }

  &__footer {
    width: 100%;
    margin-top: auto;
    text-align: right;
  }
}

.integrations-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: calc(4 * @item-side + 4 * @item-margin);
  margin-top: 24px;

  .integration-item {
    margin: 0 32px 32px 0;
  }

  .screen-sm({
    flex-direction: column;
    align-items: center;

    .integration-item {
      margin: 0 0 32px 0;
    }
  });
}

.integration-edit-modal {
  .ant-modal-body {
    padding: 25px 50px 50px;
  }
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';