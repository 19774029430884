@import 'assets/less/variables';

.home-nav-drawer {
  &__logo {
    width: 107px;
    height: 30px;
  }

  & .ant-drawer-header {
    margin: 0 20px;
    padding: 20px 0 15px 0;
  }

  & .ant-drawer-title {
    height: 30px;
  }

  & .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 24px 20px;
  }

  & .ant-drawer-close {
    top: 22px;
    width: 24px;
    height: 24px;
    padding: 0;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: flex-start;
    margin-bottom: 24px;
    border-bottom: 1px solid @gray-3;
  }

  &__link {
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 700;
    color: @dark;
  }

  &__actions {
    margin: auto 0 0 0;
    text-align: center;
  }

  &__action-link {
    margin-right: 32px;
    font-weight: 700;
    color: @dark;
  }

  &__btn {
    height: 40px;
  }
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';