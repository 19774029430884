@import 'assets/less/variables';

.departments {
  &__header {
    .table_title {
      font-size: 24px;
    }
  }

  &__name {
    margin-bottom: 0;
  }

  &__filter {
    min-width: 175px;
  }

  .departments-list {
    margin-top: -20px;
  }

  .add-department-btn {
    height: 40px;
  }
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';