@import 'assets/less/variables';

.redeem-gift {
  &__content {
    padding: 26px 0 16px 0;

    .screen-sm({
      padding: 16px 0;
    });
  }

  &__row {
    display: flex;
    padding-bottom: 26px;
    border-bottom: 1px solid @gray-3;

    .screen-sm({
      flex-direction: column;
      padding-bottom: 10px;
      font-size: 14px;
    });
  }

  &__image {
    position: relative;
    flex-shrink: 0;
    width: 240px;
    height: 150px;
    overflow: hidden;
    border-radius: @border-radius;
    background: rgba(@gray-3, 0.5);

    .screen-sm({
      width: 100%;
      padding-top: 62.5%;
      margin-bottom: 10px;
    });
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__text {
    margin-left: 50px;

    .screen-sm({
      margin-left: 0
    });
  }

  &__name {
    font-weight: 700;
  }

  &__info {
    margin: 10px 0 20px 0;

    .screen-sm({
      font-size: 14px;
    });
  }

  &__info-text {
    margin-bottom: 10px;
  }

  &__hint {
    margin-top: 5px;
    font-size: 12px;
  }
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';