@import 'assets/less/variables';

.user-activities {
  position: relative;
  max-width: 100%;
  max-height: 160px;
  padding: 15px 0;
  overflow-x: hidden;
  overflow-y: auto;
  color: @gray-1;
  border-top: 1px solid @gray-3;

  &__title {
    margin-bottom: 10px;
  }

  &__item {
    margin-bottom: 10px;
  }

  .infinite-scroll-component {
    overflow: unset !important;
  }

  .spin-wrapper {
    position: relative;
    text-align: center;
  }

  .screen-sm({
    font-size: 14px;
  });
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';