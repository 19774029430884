@import 'assets/less/variables';

.green-house-form {
  &__board-tokens {
    &.ant-select.ant-select-single .ant-select-selector {
      border: none;
    }

    &.ant-select-multiple {
      & .ant-select-selection-placeholder {
        left: 0;
        right: 0;
      }
      & .ant-select-selection-search {
        margin-inline-start: -2px;
      }
    }
    & .ant-select-selection-overflow {
      max-width: 94%;
    }
  }

  &__select-wrapper {
    position: relative;
  }

  &__info-icon {
    position: absolute;
    top: 8px;
    right: 2px;
    color: @gray-2;
  }

  &__dropdown {
    display: none !important;
  }
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';