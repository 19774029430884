@import 'assets/less/variables';

.user-points {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 220px;
  color: rgba(@light, 0.6);
  background: @color-1;

  &__text {
    margin-right: 5px;

    @media (max-width: 360px) {
      display: none;
    }
  }

  &__amount {
    color: @light;
  }

  .screen-sm({
    font-size: 14px;
  });
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';