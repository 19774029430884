@import 'assets/less/variables';

.error-boundary {
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: auto;

  &__card {
    width: 100%;
    max-width: 500px;
  }

  &__icon {
    font-size: 40px;
    color: @light;
  }

  &__title {
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }

  &__text {
    text-align: center;
  }
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';