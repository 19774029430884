@import 'assets/less/variables';

.sign-up-form {
  margin-bottom: 35px;

  &__actions {
    display: flex;
    align-items: center;
    padding-top: 20px;

    button:first-child {
      max-width: 100%;
    }

    .screen-sm({
      flex-direction: column;

      button:first-child {
        margin-bottom: 15px;
      }
    });
  }

  &__btn-divider {
    margin: 0 25px;
    font-size: 20px;
    color: @gray-2;

    .screen-sm({
      display: none;
    });
  }

  .screen-sm({
    margin-bottom: 25px;
  });
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';