@import 'assets/less/variables';

.trial-banner {
  margin: 15px 40px 0 40px;
  padding: 2px 5px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: @light;
  background: @color-1;

  .screen-lg({
    margin: 15px 0 0 0;
  });
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';