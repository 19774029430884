@import 'assets/less/variables';

// Set core body defaults
body {
  scroll-behavior: smooth;
}

// General classes
#root,
.App {
  height: 100%;
}

.no-wrap {
  white-space: nowrap;
}

// Ant design overrides
.ant-btn-background-ghost.ant-btn-primary:not(.ant-btn-dangerous) {
  background: @primary-light !important;
}

.ant-input,
.ant-input-affix-wrapper,
.ant-form-item .ant-input-number {
  padding: 16px 0;
  border: none;
  border-bottom: 1px solid @gray-2;
  border-radius: 0;

  &:focus,
  &.ant-input-affix-wrapper-focused,
  &.ant-input-number-focused {
    box-shadow: none;
  }

  & .ant-input-number-handler-wrap {
    display: none;
  }

  & .ant-input-number-input {
    padding: 0;
  }

  .screen-sm({
    font-size: 14px;
    padding: 14px 0;
  });
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  border-bottom: 1px solid @gray-2;
  border-radius: 0;
}
.ant-select.ant-select-single {
  & .ant-select-selector {
    border: none;
    border-bottom: 1px solid @gray-2;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }

    .screen-sm({
      font-size: 14px;
    });
  }

  & .ant-select-selection-item {
    color: @gray-1;
  }

  & .ant-select-selection-search {
    left: 0;
  }

  &.ant-select-lg .ant-select-selector {
    padding-left: 0;
  }
}

.ant-select.ant-select-single.ant-select-focused {
  & .ant-select-selector {
    box-shadow: none !important;
  }
}

.ant-select.ant-select.ant-select-single.ant-select-lg {
  font-size: 16px;
}

.ant-picker {
  border: none;
  border-bottom: 1px solid @gray-2;
  border-radius: 0;

  & .ant-picker-input > input {
    color: @gray-1;

    .screen-sm({
      font-size: 14px;
    });
  }

  &.ant-picker-focused,
  &:focus {
    box-shadow: none;
  }
}

.ant-picker-panels {
  .screen-sm({
    flex-direction: column;
  });
}

.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus {
  box-shadow: none;
}

.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 14px;
}

.ant-typography-copy {
  width: 24px;
  height: 24px;
}

.text {
  &__gray-2 {
    color: @gray-2;
  }

  &__primary {
    color: @color-1;
  }

  &__error {
    color: @error;
  }

  &__bold {
    font-weight: bold;
  }

  &__12 {
    font-size: 12px;
  }

  &__14 {
    font-size: 14px;
  }
}

.switch-form-item {
  display: flex;
  justify-content: space-between;

  &__label {
    margin-bottom: 10px;
    padding-bottom: 28px;
    font-size: 20px;
  }
}
