.table_title {
  span:first-child {
    margin-right: 20px;
  }

  &__count {
    display: inline-block;
    min-width: 58px;
  }

  span:first-child + &__count {
    margin-left: -14px;
  }
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';