@import 'assets/less/variables';

.status-buttons {
  display: flex;
  justify-content: space-between;
  margin-left: -30px;

  .ant-radio-button-wrapper {
    width: 100%;
    height: 40px;
    margin-left: 30px;
    line-height: 40px;
    text-align: center;
    white-space: nowrap;
    color: @gray-2;
    border: 1px solid @gray-2;
    border-radius: @border-radius;

    &.ant-radio-button-wrapper-checked {
      font-weight: 700;
      color: @primary;
      border: 1px solid @primary;
      background: @primary-light;
    }

    &:first-child:last-child {
      border-radius: @border-radius;
    }

    &:not(:first-child)::before {
      display: none;
    }

    .screen-md({
      margin-left: 0;
    });
  }

  .screen-md({
    margin-left: 0;
  });

  .screen-sm({
    flex-direction: column;

    .ant-radio-button-wrapper:not(:last-child) {
      width: 100%;
      margin-bottom: 12px;
    }
  });
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';