@import 'assets/less/variables';

.home-header {
  padding: 24px 0;
  border-bottom: 1px solid @gray-3;

  &__container {
    display: flex;
    align-items: center;

    .screen-lg({
      padding: 0;
    });
  }

  &__logo {
    display: inline-flex;
    margin-right: 115px;

    .screen-lg({
      margin: 0 auto 0 0;

      svg {
        height: 30px;
        width: 107px;
      }
    });
  }

  &__link {
    margin-right: 40px;
    font-weight: 700;
    color: @dark;
  }

  &__actions {
    margin: 0 0 0 auto;
  }

  &__action-link {
    margin-right: 32px;
    font-weight: 700;
    color: @dark;
  }

  &__btn {
    height: 43px;
  }

  &__hamburger {
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    background: transparent;
  }

  .screen-lg({
    padding: 20px 0 15px 0;
    margin: 0 20px;
  });
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';