@import 'assets/less/variables';

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__page-size {
    &.ant-select.ant-select-single .ant-select-selector {
      border: none;
    }
  }

  &__pages {
    text-align: right;

    .ant-pagination-prev,
    .ant-pagination-next {
      display: none;
    }

    .ant-pagination-item-link,
    .ant-pagination-item,
    .ant-pagination-item a {
      color: @primary;
      border-color: transparent;
      border-radius: @border-radius;
    }

    .ant-pagination-item-active {
      border: 1px solid @primary;
      background: @primary-light;
    }
  }
}

@primary-color: #00a79d;@success-color: #04aa77;@error-color: #ff003d;@warning-color: #ffa800;@layout-body-background: #ffffff;@btn-border-radius-base: 4px;@input-disabled-bg: transparent;@text-color: #1d2c4c;@font-size-base: 16px;@select-single-item-height-lg: 58px;@form-item-margin-bottom: 25px;@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';